import config from '@/config';
import { logger } from '@/helpers/logger';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';
import styles from './GoogleMap.module.scss';
import type { GoogleMapProps } from './interfaces';

const googleKey = config.googleKey;

const GoogleMap = ({
  children,
  bootstrapURLKeys,
  ...props
}: GoogleMapProps) => {
  if (!googleKey) {
    logger.error('Missing GOOGLE_API_KEY !!!');
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.map, {
          [styles.fullheight]: props.fullHeight,
          [styles.withBorderRadius]: props.withBorderRadius,
          [styles.relativePosition]: props.relativePosition,
        })}
      >
        <GoogleMapReact
          {...props}
          bootstrapURLKeys={{ ...bootstrapURLKeys, key: googleKey }}
        >
          {children}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default GoogleMap;
