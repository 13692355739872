import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { EnercityGwkFooterProps } from './types';

const EnercityGwkFooter = ({ skewOverlap }: EnercityGwkFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity AG"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
          <NewFooter.Link href="https://service.enercity.de/kuendigung">
            Verträge hier kündigen
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={34} project="enercity" solidFill />}
      skewOverlap={skewOverlap}
      socialLinksSlot={
        <>
          <NewFooter.SocialLink
            href="https://www.facebook.com/enercity/"
            iconVariant="action/facebook"
            title="enercity auf Facebook folgen"
          />
          <NewFooter.SocialLink
            href="https://twitter.com/enercity"
            iconVariant="action/twitter"
            title="enercity auf Twitter folgen"
          />
          <NewFooter.SocialLink
            href="https://www.instagram.com/enercity_ag"
            iconVariant="action/instagram"
            title="enercity auf Instagram folgen"
          />
          <NewFooter.SocialLink
            href="https://www.xing.com/pages/enercity"
            iconVariant="action/xing"
            title="enercity auf Xing folgen"
          />
          <NewFooter.SocialLink
            href="https://www.kununu.com/de/enercity"
            iconVariant="action/kununu"
            title="enercity auf kununu folgen"
          />
          <NewFooter.SocialLink
            href="https://de.linkedin.com/company/enercity-ag"
            iconVariant="action/linkedin"
            title="enercity auf LinkedIn folgen"
          />
        </>
      }
    >
      <NewFooter.LinkDirectory masonry>
        <NewFooter.LinkDirectoryGroup title="Produkte">
          <NewFooter.Link href="/geschaeftskunden/produkte/strom">
            Strom
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/gas">
            Gas
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/waerme">
            Wärme
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/elektromobilitaet">
            Elektromobilität
          </NewFooter.Link>
          <NewFooter.Link href="https://www.enercity-solution.de/">
            Solar
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/wasser">
            Wasser
          </NewFooter.Link>
          <NewFooter.Link href="https://www.enercity-contracting.de/">
            Contracting
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/objektanschluss">
            Objektanschluss
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/direktvermarktung">
            Direktvermarktung
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/smartbusiness">
            SmartBusiness
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/schulungscenter">
            Schulungsangebot
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/energieeffizienz">
            Energieeffizienz
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/netzdienstleistungen">
            Netzdienstleistungen
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/produkte/beleuchtung">
            Beleuchtungsanlagen
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Services">
          <NewFooter.Link href="/geschaeftskunden/service/umzug">
            Umzug
          </NewFooter.Link>
          <NewFooter.Link href="/geschaeftskunden/service/zahlung">
            Zahlung
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Über uns">
          <NewFooter.Link href="/konzern">Konzern</NewFooter.Link>
          <NewFooter.Link href="/newsroom">Newsroom</NewFooter.Link>
          <NewFooter.Link href="/karriere">Karriere</NewFooter.Link>
          <NewFooter.Link href="/konzern/investor-relations">
            Investor Relations
          </NewFooter.Link>
          <NewFooter.Link href="/konzern/nachhaltigkeit">
            Nachhaltigkeit
          </NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>

        <NewFooter.LinkDirectoryGroup title="Info">
          <NewFooter.Link href="https://www.enercity.de/myenercity/forms/login/index.jsx">
            Extranet
          </NewFooter.Link>
          <NewFooter.Link href="/konzern/einkauf">Einkauf</NewFooter.Link>
          <NewFooter.Link href="/downloads">Downloads</NewFooter.Link>
          <NewFooter.Link href="/newsletter">Newsletter</NewFooter.Link>
        </NewFooter.LinkDirectoryGroup>
      </NewFooter.LinkDirectory>
    </NewFooter>
  );
};

export default EnercityGwkFooter;
