export const CONTENTS_AS_PROP_COMPONENT_WHITELIST: (string | RegExp)[] = [
  /(-|^)footer$/,
  /(-|^)header$/,
  /(-|^)image-gallery$/,
  'magazine-image-slider',
  'image-map',
  'faq-accordion',
];

export const IGNORED_COMPONENT_NAMES = ['mgnl:ruleSet', 'carouselImages'];
