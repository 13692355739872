import { PRODUCTS_FORM_INFOS } from '@/consts/products';
import { logger } from '@/helpers/logger';
import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useForm from '@/hooks/useForm/useForm';
import FormField from '@uikit/components/FormField/FormField';
import { FormFieldType } from '@uikit/components/FormField/consts';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import StageBoxCalculatorFormFieldRadioGroupWrapper from '@uikit/components/StageBoxCalculatorFormFieldRadioGroupWrapper/StageBoxCalculatorFormFieldRadioGroupWrapper';
import StageBoxForm from '@uikit/components/StageBoxForm/StageBoxForm';
import React, { useEffect, useState } from 'react';
import { PRODUCT_BUTTON_ICONS, PRODUCT_OPTIONS_ORDER } from './consts';
import type {
  CustomerTypeOption,
  ProductFormType,
  ProductOption,
  StageBoxCalculatorProps,
} from './interfaces';
import schema from './schema';

const sortProductOptions = (options: ProductOption[]) =>
  options.sort(
    (a, b) =>
      PRODUCT_OPTIONS_ORDER.indexOf(a) - PRODUCT_OPTIONS_ORDER.indexOf(b)
  );

const productOptionFromProductFormType = (productFormType: ProductFormType) =>
  (productFormType.split('-') as ProductOption[]) || [];

const backgroundTypeToRadioGroupColor = {
  gradient: 'white',
  white: 'default',
} as const;

const StageBoxCalculator = ({
  title,
  productFormType,
  productCustomerType,
  powerPagePath,
  gasPagePath,
  powerAndGasPagePath,
  powerPageGwKPath,
  gasPageGwKPath,
  powerAndGasPageGwKPath,
  powerGasCombinedDefault,
  tariffRestrictions,
  backgroundType = 'gradient',
}: StageBoxCalculatorProps) => {
  const { pushDataLayerEvent } = useDataLayer();

  const productOptions: ProductOption[] = sortProductOptions(
    productOptionFromProductFormType(productFormType)
  );

  const productPageLinkMap: Record<
    ProductOption,
    Record<CustomerTypeOption, string | undefined>
  > = {
    power: {
      private: powerPagePath,
      business: powerPageGwKPath,
    },
    gas: {
      private: gasPagePath,
      business: gasPageGwKPath,
    },
    combined: {
      private: powerAndGasPagePath,
      business: powerAndGasPageGwKPath,
    },
  };

  const initialState = {
    zipcode: '',
  };

  const [productOption, setProductOption] = useState<ProductOption>(
    powerGasCombinedDefault ?? productOptions.find(Boolean) ?? 'power'
  );

  const [customerType, setCustomerType] = useState<CustomerTypeOption>(
    productCustomerType === 'business-customer' ? 'business' : 'private'
  );

  const handleOnSubmit = (formState: UseFormState) => {
    const zipcode = formState['zipcode'];
    const gasConsumption = formState['consumption.gas'];
    const powerConsumption = formState['consumption.power'];
    const pagePath = productPageLinkMap[productOption][customerType];

    pushDataLayerEvent({
      event: 'customClick2',
      plz: zipcode,
      isLeadingTo: 'S&G-Konfigurator',
      isSgkModulePresent: 'true',
      sgkProductSelection: PRODUCTS_FORM_INFOS[productOption].radioLabel,
      sgkCustomerTypeSelection:
        customerType === 'private' ? 'Privat' : 'Gewerbe',
    });

    if (pagePath) {
      const searchParams = new URLSearchParams();
      searchParams.append('zipCode', zipcode);
      if (gasConsumption) {
        searchParams.append('gasConsumption', gasConsumption);
      }
      if (powerConsumption) {
        searchParams.append('powerConsumption', powerConsumption);
      }

      if ((tariffRestrictions ?? []).length) {
        tariffRestrictions?.forEach(({ id }) => {
          searchParams.append('productIds', id);
        });
      }

      if (typeof window !== undefined) {
        window.location.href = `${pagePath}?${searchParams}`;
      }
    } else {
      logger.error(
        `'pagePath' for ${productOption} ${customerType} in CMS is not defined!`
      );
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    getErrorByFieldName,
    formState,
  } = useForm(handleOnSubmit, schema, initialState);

  const productDropdownOptions: FormFieldDropdownOption[] = productOptions.map(
    (value) => {
      return {
        label: PRODUCTS_FORM_INFOS[value].radioLabel,
        value,
      };
    }
  );

  const showDropdown = productDropdownOptions.length > 1;
  const showCustomerTypeRadio =
    productCustomerType === 'private-business-combined';

  const handleOnDropdownChange = (value: string) => {
    setProductOption(value as ProductOption);
  };

  const handleOnCustomerTypeChange = (
    $event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = $event.target;
    setCustomerType(value as CustomerTypeOption);
  };

  useEffect(() => {
    pushDataLayerEvent({
      event: 'sgkModuleUpdate',
      isSgkModulePresent: 'true',
      sgkProductSelection: PRODUCTS_FORM_INFOS[productOption].radioLabel,
      sgkCustomerTypeSelection:
        customerType === 'private' ? 'Privat' : 'Gewerbe',
    });
  }, [customerType, productOption, pushDataLayerEvent]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <StageBoxForm
          title={title}
          buttonText={PRODUCTS_FORM_INFOS[productOption].buttonText}
          buttonIcon={PRODUCT_BUTTON_ICONS[productOption]}
          backgroundType={backgroundType}
        >
          {showCustomerTypeRadio && (
            <StageBoxCalculatorFormFieldRadioGroupWrapper
              onChange={handleOnCustomerTypeChange}
              value={customerType}
              color={
                backgroundTypeToRadioGroupColor[backgroundType || 'gradient']
              }
            />
          )}

          {showDropdown && (
            <FormFieldDropdown
              options={productDropdownOptions}
              label="Tarife anzeigen für"
              name="productOption"
              value={productOption}
              isBoxOnGradient={backgroundType === 'gradient'}
              onBlur={handleBlur}
              onChange={(option) =>
                handleOnDropdownChange(option ? option.value : '')
              }
            />
          )}

          <FormField
            name={'zipcode'}
            value={formState['zipcode'] || ''}
            label={'Ihre Postleitzahl'}
            type={FormFieldType.Number}
            autoComplete="postal-code"
            inputMode="numeric"
            maxLength={5}
            onChange={handleChange}
            errorMessage={getErrorByFieldName(`zipcode`)}
            onBlur={handleBlur}
            dontAllowMoreThenMaxLength
            isBoxOnGradient={backgroundType === 'gradient'}
            errorMessageHasRelativePosition
          />
        </StageBoxForm>
      </form>
    </>
  );
};
export default StageBoxCalculator;
