import React, { useEffect, useState } from 'react';
import JobKununuScore from '../../uikit/components/JobKununuScore/JobKununuScore';
import assetPath from '../../uikit/helpers/assetPath';

const KUNUNU_URL = `/json-data/kununuScore.json`;

const KununuScoreMagnoliaWrapper = () => {
  const [kununuScore, setKununuScore] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${assetPath}${KUNUNU_URL}`);

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      setKununuScore(data.all.points);
    };

    fetchData();
  }, []);

  return <JobKununuScore score={kununuScore} />;
};

export default KununuScoreMagnoliaWrapper;
