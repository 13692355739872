import clsx from 'clsx';
import Button from '../Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonType,
  ShowAddon,
  ShowIcon,
} from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import StageBox from '../StageBox/StageBox';
import styles from './StageBoxForm.module.scss';
import type { StageBoxFormProps } from './interfaces';

const StageBoxForm = ({
  title,
  buttonIcon,
  buttonText,
  children,
  backgroundType = 'gradient',
}: StageBoxFormProps) => {
  return (
    <StageBox backgroundType={backgroundType}>
      <div className={clsx(styles.stageBox, styles[backgroundType])}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.form}>{children}</div>
        <Button
          className={styles.submitButton}
          type={ButtonType.Submit}
          actionType={ClickableAction.None}
          align={ButtonAlign.Left}
          color={
            backgroundType === 'gradient'
              ? ButtonColor.Secondary
              : ButtonColor.Primary
          }
          showAddon={ShowAddon.Gradient}
          showIcon={ShowIcon.ShowIcon}
          icon={buttonIcon}
          size={ButtonSize.Large}
          noClickable
        >
          {buttonText}
        </Button>
      </div>
    </StageBox>
  );
};

export default StageBoxForm;
