import type { TableData } from '@uikit/components/Table/types';
function getHtmlContent(
  nodes: NodeListOf<ChildNode>,
  start: number,
  end: number
): string {
  return Array.from(nodes)
    .slice(start, end)
    .map((node) => (node instanceof HTMLElement ? node.outerHTML : null))
    .filter(Boolean)
    .join('');
}

function extractTableData(table: HTMLTableElement): TableData {
  const head = Array.from(table.querySelectorAll('thead th')).map(
    (th) => th.textContent?.trim() || ''
  );

  const body = Array.from(table.querySelectorAll('tbody tr')).map((tr) =>
    Array.from(tr.querySelectorAll('td')).map((td) => td.innerHTML.trim())
  );

  const caption = table.querySelector('caption')?.textContent?.trim() || '';

  return { head, body, ...(caption && { caption }) };
}

export function parseTable(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const nodes = doc.body.childNodes;
  const tables = Array.from(doc.querySelectorAll('table'));

  return tables.map((table, index) => {
    const tableIndex = Array.from(nodes).indexOf(table);
    const htmlBefore = index === 0 ? getHtmlContent(nodes, 0, tableIndex) : '';
    const htmlAfter = getHtmlContent(
      nodes,
      tableIndex + 1,
      index === tables.length - 1
        ? nodes.length
        : Array.from(nodes).indexOf(tables[index + 1])
    );

    const tableData = extractTableData(table);

    return {
      htmlBefore,
      htmlAfter,
      tableData,
    };
  });
}
