import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import clsx from 'clsx';
import parseCustomMarkdown from '../../helpers/parseCustomMarkdown';
import styles from './Table.module.scss';
import findAdditionalHeadline from './findAdditionalHeadline';
import type { TableProps } from './types';

const Table = ({ tableData, layout = 'auto', color = 'blue' }: TableProps) => {
  const convert = (value: string) =>
    parseCustomMarkdown(value.replace('#titel', ''));

  const hasAdditionalHeadline = findAdditionalHeadline(tableData);

  const className = clsx(styles.base, styles[color], {
    [styles.hasAdditionalHeadline]: hasAdditionalHeadline,
  });

  return (
    <div className={className}>
      <table className={styles[layout]}>
        {tableData.caption && <caption>{tableData.caption}</caption>}
        {tableData.head && (
          <thead>
            <tr
              className={clsx({
                [styles.additionalHeadlineRow]:
                  tableData.head[0]?.includes('#titel'),
              })}
            >
              {tableData.head.map((th, idx) => (
                <th key={idx} {...getSafeInnerHTMLProp(convert(th))} />
              ))}
            </tr>
          </thead>
        )}
        {tableData.body && (
          <tbody>
            {tableData.body.map((tr, idx) => (
              <tr
                key={idx}
                className={clsx({
                  [styles.additionalHeadlineRow]: tr[0].includes('#titel'),
                })}
              >
                {tr.map((td, jdx) => (
                  <td key={jdx}>
                    <div {...getSafeInnerHTMLProp(convert(td))} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
