import { TableData } from './types';

const checkForAdditionalHeadline = (tableData: TableData): boolean => {
  return (
    tableData.body.some((row) => row[0]?.includes('#titel')) ||
    (!!tableData.head && tableData.head[0]?.includes('#titel'))
  );
};

export default checkForAdditionalHeadline;
